const locallifeSettlement = {
  state: {
    pageType: 'List',
    settlementDetail: {},
    billId: 0,
    beginUpdate: new Date()

  },
  mutations: {
    set_locallifesettlement_pageType (state, data) {
      state.pageType = data
    },
    set_locallifesettlement_settlementDetail (state, data) {
      state.settlementDetail = data
    },
    set_locallifesettlement_billId (state, data) {
      state.billId = data
    },
    set_locallifesettlement_update (state, data) {
      state.beginUpdate = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default locallifeSettlement
